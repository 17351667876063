<template>
  <Layout :pagetitle="'Produto'">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              {{ $route.params.idProduct ? "Editar Produto" : "Cadastrar Produto" }}
            </h4>
          </div>
          <div class="card-body">
            <div>
              <div class="row mb-4">
                <label for="title" class="col-form-label col-lg-2">Título*</label>
                <div class="col-lg-10">
                  <input
                    id="title"
                    name="title"
                    type="text"
                    class="form-control"
                    placeholder="Informe o título..."
                    v-model="product.title"
                  />
                </div>
              </div>

              <div class="row mb-4">
                <label for="productdesc" class="col-form-label col-lg-2">Descrição*</label>
                <div class="col-lg-10">
                  <textarea
                    class="form-control"
                    id="productdesc"
                    rows="3"
                    placeholder="Informe uma descrição detalhada..."
                    v-model="product.desc"
                  ></textarea>
                </div>
              </div>

              <div class="row mb-4">
                <label for="product" class="col-form-label col-lg-2">Valor*</label>
                <div class="col-lg-10">
                  <CurrencyInput v-model="product.price" :options="{ currency: 'BRL', autoDecimalDigits: true }"/>
                </div>
              </div>

              <div class="row mb-4">
                <label for="product" class="col-form-label col-lg-2">Valor Promocional</label>
                <div class="col-lg-10">
                  <CurrencyInput v-model="product.promoPrice" :options="{ currency: 'BRL', autoDecimalDigits: true }"/>
                </div>
              </div>

              <div class="row mb-4">
                <label for="product" class="col-form-label col-lg-2">Categoria*</label>
                <div class="col-lg-10">
                  <CategorySelector
                    @onChange="onchangeCategory"
                    :nameField="product.idCategory"
                    :idCompany="company._id"
                  />
                </div>
              </div>

              <div class="row mb-4" v-if="product.link">
                <label for="product" class="col-form-label col-lg-2">Plataforma</label>
                <div class="col-lg-10">
                  <PlatformSelector
                    @onChange="onchangePlatform"
                    :nameField="product.idPlatform"
                  />
                </div>
              </div>

              <div class="row mb-2">
                <label for="product" class="col-form-label col-lg-2">Destaque</label>
                <div class="col-lg-10">
                  <div class="form-check form-switch form-switch-md" dir="ltr">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="SwitchCheckSizemd"
                      v-model="product.highlight"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <label for="link" class="col-form-label col-lg-2">Link</label>
                <div class="col-lg-10">
                  <div class="form-check form-switch form-switch-md" dir="ltr">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="SwitchCheckSizemd"
                      v-model="product.link"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-4" v-if="product.link">
                <label for="link" class="col-form-label col-lg-2">URL Link</label>
                <div class="col-lg-10">
                  <textarea
                    class="form-control"
                    id="link"
                    rows="3"
                    placeholder="Informe a URL do link..."
                    v-model="product.urlLink"
                  ></textarea>
                </div>
              </div>

              <div class="row mb-2">
                <label for="product" class="col-form-label col-lg-2">Ativo</label>
                <div class="col-lg-10">
                  <div class="form-check form-switch form-switch-md" dir="ltr">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="SwitchCheckSizemd"
                      v-model="product.active"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-form-label col-lg-2 d-flex justify-content-between align-items-center">
                  <label for="product" class="mt-2">Características*</label>
                  <button
                    :class="`btn-sm btn-rounded waves-effect waves-light btn-${company.color ? company.color : 'primary'}`"
                    @click="addFeature()"
                  >
                    <eva-icon class="icon font-size-16 mb-1" name="plus-outline"></eva-icon>
                  </button>
                </div>
                <div class="col-lg-10">
                  <div class="d-flex mt-2" v-for="(_, index) in product.features" :key="index">
                    <input
                      type="text"
                      class="form-control pr-2"
                      placeholder="Informe uma característica..."
                      v-model="product.features[index]"
                    />
                    <button
                      :class="`btn-sm btn-rounded waves-effect waves-light btn-light`"
                      @click="removeFeature(index)"
                    >
                      <eva-icon class="icon font-size-16 mb-1" name="trash-2-outline"></eva-icon>
                    </button>
                  </div>
                </div>
              </div>

              <div class="row mb-2" v-if="$route.params.idProduct">
                <label for="product" class="col-form-label col-lg-2">Data de Cadastro</label>
                <div class="col-lg-10">
                  <span :class="`badge-lg p-1 px-2 rounded-pill badge-soft-${company.color ? company.color : 'primary'}`">
                    {{ $utils.formatDateTime(product.createdAt) }}
                  </span>
                </div>
              </div>

              <div class="row mb-2" v-if="product.updatedAt">
                <label for="product" class="col-form-label col-lg-2">Data de Modificação</label>
                <div class="col-lg-10">
                  <span :class="`badge-lg p-1 px-2 rounded-pill badge-soft-${company.color ? company.color : 'primary'}`">
                    {{ $utils.formatDateTime(product.updatedAt) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title mb-0">Imagem</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-lg-6" v-if="product.imageUrl">
                <label class="form-label mt-lg-2">Imagem Atual</label>
                <div class="logo-lg mt-1 text-center">
                  <img
                    class="w-75 max-width-300px h-75 max-height-200px"
                    :src="product.imageUrl ? product.imageUrl : ''"
                    alt="Imagem"
                  />
                </div>
              </div>

              <div class="col-12 col-lg-6 mt-4 mt-lg-0">
                <input
                  class="form-control"
                  @change="handleImage('image')"
                  type="file"
                  ref="imageInput"
                  accept="image/jpeg, image/jpg, image/png"
                />
                <div class="logo-lg mt-3 text-center" v-if="image">
                  <img
                    class="w-75 max-width-300px h-75 max-height-200px"
                    :src="imagePreview"
                    alt="Nova Imagem"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div
        v-if="isLoading"
        :class="`spinner-border m-1 text-${company.color ? company.color : 'primary'}`"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else class="d-flex justify-content-end">
        <button
          :class="`btn btn-rounded waves-effect waves-light col-6 col-md-2 btn-${company.color ? company.color : 'primary'}`"
          @click="$router.push({ name: 'companyProducts' })"
        >
          <span>Voltar</span>
        </button>
        <button
          :class="`btn btn-rounded waves-effect waves-light col-6 col-md-2 mx-lg-2 btn-${company.color ? company.color : 'primary'}`"
          @click="onSave()"
        >
          <span>Salvar</span>
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import CurrencyInput from "../../../components/common/CurrencyInput.vue";
import CategorySelector from "../../../components/common/CategorySelector.vue";
import PlatformSelector from "../../../components/common/PlatformSelector.vue";

export default {
  data() {
    return {
      company: JSON.parse(localStorage.getItem("company")),
      product: {
        active: true,
        link: false,
        features: [""],
        highlight: false
      },
      selectedCategory: null,
      selectedPlatform: null,
      image: null,
      imagePreview: null,
    };
  },
  components: {
    Layout,
    CurrencyInput,
    CategorySelector,
    PlatformSelector,
  },
  computed: {
    ...mapState(["isLoading"]),
  },
  async created() {
    if (this.$route.params && this.$route.params.idProduct) {
      this.setLoading(true);
      await this.$api
        .get(`product/${this.$route.params.idProduct}`)
        .then((res) => {
          this.setLoading(false);
          this.product = res.data;
        })
        .catch((err) => {
          this.setLoading(false);
          this.$customAlert({
            type: "error",
            msg: err?.data?.message || "Erro ao buscar produto",
          });
        });
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    handleImage(imageRef) {
      const fileInputRef = `${imageRef}Input`;
      this[imageRef] = this.$refs[fileInputRef].files[0];

      if (this[imageRef]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this[`${imageRef}Preview`] = e.target.result;
        };
        reader.readAsDataURL(this[imageRef]);
      } else {
        this[`${imageRef}Preview`] = null;
      }
    },
    onchangeCategory(idCategory) {
      this.selectedCategory = idCategory;
    },
    onchangePlatform(idPlatform) {
      this.selectedPlatform = idPlatform;
    },
    addFeature() {
      this.product.features.push("");
    },
    removeFeature(index) {
      this.product.features.splice(index, 1);
    },
    validateProduct(product) {
      if (!product.title) {
        return "Informe um título";
      }
      if (!product.desc) {
        return "Informe uma descrição";
      }
      if (!product.price || product.price <= 0) {
        return "Informe um valor";
      }
      if (product.promoPrice && product.promoPrice <= 0) {
        return "Informe um valor promocional válido";
      }
      if (!product.features || product.features.length < 4) {
        return "Informe pelo menos 4 características";
      }
      if (!this.selectedCategory) {
        return "Informe uma categoria";
      }
      if (!this.product?.urlLink && this.product?.link) {
        return "Informe uma URL Link";
      }
      if (!this.selectedPlatform && this.product?.link) {
        return "Informe uma plataforma";
      }
      return null;
    },
    onSave() {
      const validationMessage = this.validateProduct(this.product);

      if (validationMessage) {
        this.$customAlert({
          type: "warning",
          msg: validationMessage,
        });
        return;
      }

      const header = {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      };

      const formData = new FormData();
      formData.append("idCategory", this.selectedCategory);
      if (this.selectedPlatform) {
        formData.append("idPlatform", this.selectedPlatform);
      }
      formData.append("title", this.product.title);
      formData.append("desc", this.product.desc);
      formData.append("link", this.product.link);
      if (this.product.urlLink) {
        formData.append("urlLink", this.product.urlLink);
      }
      formData.append("price", this.product.price);
      formData.append("highlight", this.product.highlight);
      formData.append("active", this.product.active);
      if (this.product.promoPrice) {
        formData.append("promoPrice", this.product.promoPrice);
      }
      this.product.features.forEach((feature) => {
        formData.append("features", feature);
      });
      if (this.image) {
        formData.append("image", this.image);
      }

      if (this.$route.params.idProduct) {
        const data = new Date();
        formData.append(
          "updatedAt",
          moment(data).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        );

        this.setLoading(true);
        this.$api
          .put(`product/${this.product._id}`, formData, header)
          .then(() => {
            this.setLoading(false);
            this.$customAlert({
              type: "success",
              msg: "Produto atualizado com sucesso",
            });
            this.$router.push({ name: "companyProducts" });
          })
          .catch((err) => {
            this.setLoading(false);
            this.$customAlert({
              type: "error",
              msg: err?.data?.message || "Erro ao atualizar produto",
            });
          });
      } else {
        formData.append("idCompany", this.company._id);

        this.setLoading(true);
        this.$api
          .post(`product`, formData, header)
          .then(() => {
            this.setLoading(false);
            this.$customAlert({
              type: "success",
              msg: "Produto cadastrado com sucesso",
            });
            this.$router.push({ name: "companyProducts" });
          })
          .catch((err) => {
            this.setLoading(false);
            this.$customAlert({
              type: "error",
              msg: err?.data?.message || "Erro ao cadastrar produto",
            });
          });
      }
    },
  },
};
</script>

<style scoped></style>
